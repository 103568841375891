<app-form-input-dynamic [formConfig]="formConfig" #formModify (onSaveDone)="onSaveDone()"></app-form-input-dynamic>
<div *nzModalTitle class="title-modal">{{formModify.mode |translate}} {{'project.label' | translate}}</div>
<div *nzModalFooter>
  <ng-container *ngIf="formModify.mode == itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{'close' | translate}}</button>
    <button nz-button nzType="primary" (click)="edit()">{{'edit' | translate}}</button>
  </ng-container>
  <ng-container *ngIf="formModify.mode == itemMode.Edit || formModify.mode == itemMode.Add">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{'close' | translate}}</button>
    <button nz-button nzType="primary" (click)="onSave()">{{'save' | translate}}</button>
  </ng-container>
</div>
